import { HttpClient, HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { BaseComponent } from "../base/base.component";
import { Location } from '@angular/common';
import { ModalDirective } from "ngx-bootstrap/modal";
import { webService } from "../../webServices/api";
import { Constants } from "../../helpers/Constants";
import { RequestedModel } from "../../models/RequestedModel";
import { WebService } from "../../services/WebService";
import { Helpers } from "../../helpers/Helpers";
import { GenreModel } from "../../models/GenreModel";
import { PremiumComponent } from "../base/premium.component";

declare var googletag: any;
declare var window: any;
declare var $: any;

@Component({
    templateUrl: 'genre.component.html',
})
export class GenreComponent extends PremiumComponent implements OnInit, AfterViewInit {
    onRequestSongDonated(): void {
        
    }
    onUpvoteDonated(): void {
        
    }
    updateRequestASong(): void {
        
    }
    tipDJ(): void {
        
    }
    @ViewChild('checkingGenreRequestModal') public checkingGenreRequestModal: ModalDirective;

    originGenres = [];
    genres = [];
    currentGenreSelected = "";
    isRunning = false;
    progressValue = 3;
    songAd = [];
    isAdLoaded = false;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public authAppService: AuthService,
        public appService: AppService,
        public http: HttpClient,
        public webService: WebService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public location: Location
    ) {
        super(
            router,
            route,
            http,
            webService,
            toastr,
            spinner,
            authAppService,
            appService,
            location
          );
    }

    ngOnInit(): void {
        this.initGenre();
        this.loadEvent();
    }

    onDestroy(): void {

    }

    loadAd() {
        if (this.isAdLoaded)
            return;
        this.loadExternalScript(
            "https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ).then(() => {
            this.isAdLoaded = true;
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(() => {
                this.songAd.forEach((s) => {
                    googletag
                        .defineSlot("/1058397,22749104887/uJay/uJ-GR-1", [320, 50], s)
                        .addService(googletag.pubads());
                });
                googletag.pubads().enableSingleRequest();
                googletag.pubads().collapseEmptyDivs();
                googletag.enableServices();
                this.songAd.forEach((s) => {
                    // $(s).css("height", "50px");
                    googletag.display(s);
                });
            });
        });
    }

    ngAfterViewInit(): void {
        this.handlePayment();
    }

    btnCancelRequestClicked() {
        this.isRunning = false;
        this.progressValue = 3;
        this.checkingGenreRequestModal.hide();
    }

    onGenreSelected(genre: string): void {
        this.progressValue = 3;
        this.isRunning = true;
        this.currentGenreSelected = genre;
        this.checkingGenreRequestModal.show();
        this.handleCheckCountdown(genre);
    }

    async initGenre() {
        let adIdString = "ad_genre_" + 1;
        let g = { Title: "Americana", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Blues", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Classical", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Country", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Dance", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);

        adIdString = "ad_genre_" + 1;
        g = { Title: "", IsAd: true, AdId: adIdString } as GenreModel;
        this.originGenres.push(g);
        this.songAd.push(adIdString);

        g = { Title: "Deep House", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Dubstep", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Drum and Bass", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "EDM", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Electro House", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);

        adIdString = "ad_genre_" + 2;
        g = { Title: "", IsAd: true, AdId: adIdString } as GenreModel;
        this.originGenres.push(g);
        this.songAd.push(adIdString);

        g = { Title: "Electronic", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Electronica", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Folk", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Future House", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Gospel", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);

        adIdString = "ad_genre_" + 3;
        g = { Title: "", IsAd: true, AdId: adIdString } as GenreModel;
        this.originGenres.push(g);
        this.songAd.push(adIdString);

        g = { Title: "Hip-Hop", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "House", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Instrumental", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Jazz", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Latin", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);

        adIdString = "ad_genre_" + 4;
        g = { Title: "", IsAd: true, AdId: adIdString } as GenreModel;
        this.originGenres.push(g);
        this.songAd.push(adIdString);

        g = { Title: "Metal", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Minimal", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Pop", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Progressive House", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "R&B", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);

        adIdString = "ad_genre_" + 5;
        g = { Title: "", IsAd: true, AdId: adIdString } as GenreModel;
        this.originGenres.push(g);
        this.songAd.push(adIdString);

        g = { Title: "Rap", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Reggae", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Rock", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Soul", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Spanish", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);

        adIdString = "ad_genre_" + 6;
        g = { Title: "", IsAd: true, AdId: adIdString } as GenreModel;
        this.originGenres.push(g);
        this.songAd.push(adIdString);

        g = { Title: "Tech House", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Techno", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);
        g = { Title: "Trap", IsAd: false, AdId: "" } as GenreModel;
        this.originGenres.push(g);

        this.resetGenre();

        await this.delay(5000);
    }

    resetGenre() {
        this.genres = [];
        this.originGenres.forEach(i => {
            this.genres.push(i);
        });
    }

    searchKeywordChanged(q: string) {
        if (q === '') {
            this.resetGenre();
            return;
        }

        var result = Helpers.find(this.originGenres, q);
        this.genres = [];
        result.forEach(i => {
            this.genres.push(i);
        });
    }

    handleCheckCountdown(genre: string) {
        setTimeout(() => {
            this.progressValue--;
            if (this.progressValue < 0) {
                this.progressValue = 0;
                this.isRunning = false;
                this.checkingGenreRequestModal.hide();
                this.requestType = '4';
                this.requestGenre(
                    '',
                    '',
                    '',
                    '',
                    '',
                    this.event.key,
                    this.event.eventName,
                    '',
                    '',
                    '',
                    '',
                    '$0.00',
                    '0',
                    this.event.uid,
                    this.event.djEmail,
                    genre,
                    this.requestType
                );
            }
            if (this.isRunning) {
                this.handleCheckCountdown(genre);
            }
        }, 1000);
        this.checkingGenreRequestModal.show();
    }

    requestGenre(
        uid: string,
        email: string,
        firstName: string,
        lastName: string,
        avatar: string,
        eventKey: string,
        eventName: string,
        songKey: string,
        songName: string,
        songArtist: string,
        songThumb: string,
        packageName: string,
        packageId: string,
        djUid: string,
        djEmail: string,
        shoutoutMessage: string,
        requestType: string
    ) {
        const params = new HttpParams()
            .set('uid', uid)
            .set('email', email)
            .set('firstName', firstName)
            .set('lastName', lastName)
            .set('avatar', avatar)
            .set('eventKey', eventKey)
            .set('eventName', eventName)
            .set('songKey', songKey)
            .set('songName', songName)
            .set('songArtist', songArtist)
            .set('songThumb', songThumb)
            .set('packageName', packageName)
            .set('packageId', packageId)
            .set('djUid', djUid)
            .set('djEmail', djEmail)
            .set('requestValue', shoutoutMessage)
            .set('requestType', requestType);

        this.spinner.show();

        this.http
            .post(webService.userRequest, params)
            .subscribe((root: RequestedModel) => {
                this.spinner.hide();
                if (root.code === 0) {
                    this.currentRequestedKey = root.data;

                    localStorage.setItem(Constants.SETTINGS_CURRENT_REQUESTED_KEY, this.currentRequestedKey);
                    localStorage.setItem(Constants.SETTINGS_REQUEST_TYPE, requestType);
                    localStorage.setItem(Constants.SETTINGS_LATEST_REQUEST, Date.now().toString());

                    if (this.event.isTipping === "1") {
                        if (
                            this.event.profileUrl === "" ||
                            this.event.profileUrl === "null" ||
                            typeof this.event.profileUrl === "undefined"
                          ) {
                            this.event.profileUrl = "../../assets/img/ic_default_user.png";
                          }
                        this.tipModalComponent.profileUrl = this.event.profileUrl;
                        this.tipModalComponent.djName = this.event.djName;
                        this.tipModalComponent.djEmail = this.event.djEmail;
                        this.tipModalComponent.updateItemIcon();
                        this.tipModalComponent.subTitle = " got your genre request."
                        this.tipModalComponent.tipModal.show();
                    }

                    // this.afterRequestModalComponent.title = "Request Another Genre";

                    this.toastr.success('Information', this.event.djName + ' has received your genre request.');
                } else {
                    this.toastr.error('Error', 'Something went wrong');
                }
            });
    }

    googlePlaceSelected(): void {
        
    }
}