import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { SpotifyTokenModel } from "../models/SpotifyTokenModel";
import { RootSpotifyTrackModel } from "../models/SpotifyTrackModel";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { RootSongModel } from "../models/SongModel";

@Injectable()
export class SpotifyService {
  constructor(private http: HttpClient) {}

  getSpotifyToken() {
    const params = new HttpParams().set("grant_type", "client_credentials");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic M2NkN2E1MWIyZmM0NDAxNmFjZjUwYjYzMTMwMjBhMzA6MjI0YzFhYzZmZmEzNGZkNzg4MzlkZDI2Zjg2ZDU2NzA=",
      }),
    };
    return this.http.post<SpotifyTokenModel>(
      "https://accounts.spotify.com/api/token",
      params,
      httpOptions
    );
  }

  searchSong(q: string, access_token: string) : Observable<RootSpotifyTrackModel>{
    const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + access_token,
        }),
      };
      return this.http.get<RootSpotifyTrackModel>(
        "https://api.spotify.com/v1/search?q=" + q + "&type=track",
        httpOptions
      ).pipe(
        catchError(error => {
          var errorMsg: string;
          this.getSpotifyToken().subscribe(result => {
            access_token = result.access_token;
            localStorage.setItem("SPOTIFY_TOKEN", result.access_token);
            this.searchSong(q, access_token);
          })
          return throwError(errorMsg);
        })
      );;
  }

  searchFirstFridaySong(q: string) : Observable<RootSongModel>{
    const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      };
      return this.http.get<RootSongModel>(
        "https://us-central1-mejay-1542341684378.cloudfunctions.net/web/search?q=" + q,
        httpOptions
      ).pipe(
        catchError(error => {
          var errorMsg: string;
          return throwError(errorMsg);
        })
      );;
  }
}
