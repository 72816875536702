import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpParams } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { webService } from "../../webServices/api";
import { EventDetailsModel } from "../../models/EventDetailModel";
import { PlaylistModel } from "../../models/PlaylistModel";
import { RootSongModel, SongModel } from "../../models/SongModel";
import { AnimationOptions } from "ngx-lottie";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SpotifyService } from "../../services/SpotifyService";
import { SpotifyTrackModel } from "../../models/SpotifyTrackModel";
import { CommonModel } from "../../models/CommonModel";
import { EventModel, RootEventModel } from "../../models/EventModel";
import { RequestedModel } from "../../models/RequestedModel";
import { AMZUpdateCheckoutModel } from "../../models/AMZUpdateCheckout";
import { Constants } from "../../helpers/Constants";
import { RootConfigModel } from "../../models/ConfigModel";
import { AuthService } from "../../auth/AuthService";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { WebService } from "../../services/WebService";
import { TipDJModalComponent } from "../base/tipDJModal/TipDJModal.component";
import { Helpers } from "../../helpers/Helpers";
import { TipComponent } from "../base/tip.component";
import { RootSongRequestModel, SongRequestModel } from "../../models/SongRequestModel";
import { GeoPosition } from "../../helpers/GeoPosition";

declare var $: any;
declare var window: any;
declare let loginOptions: any;

@Component({
  templateUrl: "detail.component.html",
})
export class DetailComponent
  extends TipComponent
  implements OnInit, AfterViewInit {

  @ViewChild("usernameModal") public usernameModal: ModalDirective;
  @ViewChild("checkingRequestModal")
  public checkingRequestModal: ModalDirective;
  @ViewChild("searchSongModal") public searchSongModal: ModalDirective;
  @ViewChild("locationModal") public locationModal: ModalDirective;
  @ViewChild("askNumberModal") public askNumberModal: ModalDirective;
  @ViewChild("menuModal") public menuModal: ModalDirective;
  @ViewChild("helpModal") public helpModal: ModalDirective;
  @ViewChild("upvoteModal") public upvoteModal: ModalDirective;
  @ViewChild("radiusModal") public radiusModal: ModalDirective;
  @ViewChild(TipDJModalComponent) tipDJModalComponent: TipDJModalComponent;

  uuid: string;
  coin = 0;

  key: string;
  playlists = [];
  searchSongs = [];
  event: EventModel;
  number: string;

  radius = 2;
  radiusString = "Normal Venue";

  currentSongSelected: SongModel;
  currentSongThumb: string;
  currentSongTitle: string;
  currentSongKey: string;
  currentAppleMusic: string;
  currentSongArtist: string;
  currentRequestedBy: string;

  amazonCheckoutSessionId: string;

  shoutoutMessage = "";
  shoutoutPlaceholder =
    "Type your message. \nEx. I’d like to give a shoutout to ";
  requestType = "1";
  countSongRequest = 0;
  unlockGiftDes = "Only 5 More Requests";

  findLocationTitle = "Find Nearby DJ Session";
  findLocationDes =
    "Tell us your current location or a location near the session you’re looking for.";

  currentRequestedKey = "";

  notifyTitle = "";

  hasNoData = false;
  noLocation = false;
  isKaraoke = false;
  djUid = "";
  payloadJSON: string;
  signature: string;
  events = [];

  djName: string;
  profileUrl: string;
  hasSponsor: boolean;

  defaultImage = "../../assets/images/image01.png";

  config = {
    img_item_01: "",
    img_item_02: "",
    img_item_03: "",
    item: "",
    img_reward_01: "",
    img_reward_02: "",
    img_reward_03: "",
    hasSponsor: "",
  };

  searchKeyword: string;
  shareLink: string;
  isRunning = false;
  progressValue = 3;
  options: AnimationOptions = {
    path: "/assets/megaphone.json",
  };
  tickerOptions: AnimationOptions = {
    path: "/assets/ticker.json",
  };
  spotifyOptions: AnimationOptions = {
    path: "/assets/spotify.json",
  };

  phoneTypeFormGroup: FormGroup;
  @Input() phoneType: string;
  selectedCountryCode = "us";
  phoneCode = "+1";
  countryCodes = ["us", "ca", "vn"];

  btnShoutoutClickedEvent: any;
  btnTipClickedEvent: any;
  btnMenuClickedEvent: any;
  getSpotifyPlaylistEvent: any;

  playlistCountdown = "The Next Playlist Starts In 20 mins 00 secs";
  timeinterval: any;

  constructor(
    public analytics: AngularFireAnalytics,
    public router: Router,
    public spotify: SpotifyService,
    public route: ActivatedRoute,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public authAppService: AuthService,
    public fb: FormBuilder,
    public appService: AppService,
    public location: Location
  ) {
    super(router, route, http, webService, toastr, spinner, authAppService,
      appService,
      location
    );
    this.btnShoutoutClickedEvent =
      this.appService.btnShoutoutClicked.subscribe((event) => {
        this.btnShoutoutClicked();
      });

    this.btnTipClickedEvent =
      this.appService.btnTipClicked.subscribe((event) => {
        this.btnTipClicked();
      });

    this.btnMenuClickedEvent =
      this.appService.btnMenuClicked.subscribe((event) => {
        this.menuModal.show();
      });

    this.btnWrongVenueClickedEvent =
      this.appService.btnWrongVenueClicked.subscribe((event) => {
        this.btnWrongVenueClicked();
      });

    this.getSpotifyPlaylistEvent =
      this.appService.getSpotifyPlaylist.subscribe((event) => {
        this.GetSpotifyPlaylistAndGoTo();
      });

    window.addEventListener("message", (event) => {
      if (event.data.toString().includes("mins")) {
        this.playlistCountdown = "The Next Playlist Starts In " + event.data;
      }
      if (event.data.toString().includes("REFRESH_TICKER")) {
        console.log("REFRESH_TICKER");
        this.GetSpotifyPlaylist();
        this.GetSongRequestTicker();
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.key = params["key"];
      this.uuid = params["dj"];
      if (this.key !== undefined) {
        this.appService.IsShoutout = true;
        this.getSessionDetail(this.key);
      } else if (this.uuid !== undefined) {
        this.appService.IsShoutout = true;
        this.getSessionByUser(this.uuid, true);
      } else {
        this.sessionType = 3;
        this.initStandardSession();
        this.getUserLocation(true, false);
      }

      this.number = params["n"];
      if (this.number !== undefined) {
        localStorage.setItem(Constants.SETTINGS_NUMBER, this.number);
      } else {
        this.number = localStorage.getItem(Constants.SETTINGS_NUMBER);
      }
      this.amazonCheckoutSessionId = params["amazonCheckoutSessionId"];
      if (this.amazonCheckoutSessionId !== undefined) {
        localStorage.setItem(
          "amazonCheckoutSessionId",
          this.amazonCheckoutSessionId
        );
        if (this.requestType == "2") {
          this.tipDJ();
        } else {
          this.updateRequestASong();
        }
      }
    });
  }

  btnWrongVenueClicked() {
    if (this.sessionType == 3) {
      this.getUserLocation(true, true);
    } else {
      this.getUserLocation(false, true);
    }
  }

  getUserLocation(isFirst: boolean, isResetLocation: boolean) {
    if (navigator.geolocation) {
      this.spinner.show();
      this.getCurrentLocation(isFirst, isResetLocation);
    } else {
      this.handleLocationFailed(isFirst);
    }
  }

  handleLocationFailed(isFirst: boolean) {
    this.lat = localStorage.getItem(Constants.SETTINGS_LAT);
    this.lng = localStorage.getItem(Constants.SETTINGS_LNG);
    this.loadGoogleScript();
    if (this.lat === undefined || this.lat === null) {
      if (this.key === undefined) {
        this.noLocation = true;
      }
      this.spinner.hide();
      if (this.sessionType != 3) {
        this.askLocationModal.show();
      }
    } else {
      this.lng = localStorage.getItem(Constants.SETTINGS_LNG);
      this.address = localStorage.getItem("address");
      this.appService.Venue = this.address;
      if (this.sessionType == 3) {
        this.GetSongRequestByStandard();
        this.GetSpotifyPlaylist();
      } else {
        this.getSession(isFirst);
      }
    }
  }

  onLocationSuccess(position: any, isFirst: boolean) {
    this.noLocation = false;
    this.lat = position.coords.latitude;
    this.lng = position.coords.longitude;
    Helpers.saveLocation(this.lat, this.lng);
    this.loadGoogleScript();
    this.convertLatLngToAddress();
    if (isFirst) {
      this.getSession(isFirst);
    }
    this.GetSpotifyPlaylist();
  }

  onLocationFailed(error: any, isFirst: boolean, isResetLocation: boolean) {
    this.spinner.hide();
    this.loadGoogleScript();
    if (this.sessionType != 3) {
      this.askLocationModal.show();
    } else {
      this.locationGoogleModal.show();
    }
  }

  getCurrentLocation(isFirst: boolean, isResetLocation: boolean) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.onLocationSuccess(position, isFirst);
      },
      (error) => {
        this.onLocationFailed(error, isFirst, isResetLocation);
      }, {
      enableHighAccuracy: true,
      timeout: 90000,
      maximumAge: 0,
    }
    );
  }

  checkFirstTime() {
    if (this.sessionType == 3) {
      var isFirstTime = localStorage.getItem(
        Constants.SETTINGS_FIRST_TIME
      );
      if (isFirstTime == null) {
        this.helpModal.show();
        localStorage.setItem(Constants.SETTINGS_FIRST_TIME, "1");
      } else {
        this.initStandardSession();
        // this.getUserLocation(true, false);
      }
    }
  }

  initStandardSession() {
    this.appService.IsTipping = false;
    this.appService.IsShoutout = false;
    this.appService.Venue = "";
    this.isKaraoke = true;
  }

  onDestroy(): void {
    this.btnShoutoutClickedEvent.unsubscribe();
    this.btnTipClickedEvent.unsubscribe();
  }

  private initForm() {
    this.phoneTypeFormGroup = this.fb.group({
      phoneType: [this.phoneType],
      phone: ["", [Validators.pattern("[A-Za-z0-9-_ ()]+")]],
    });
  }

  radiusChanged(value) {
    this.radius = value;
    if (this.radius == 1) {
      this.radiusString = "Small Venue";
    } else if (this.radius == 2) {
      this.radiusString = "Normal Venue";
    } else {
      this.radiusString = "Large Venue";
    }
  }

  UpdateCheckoutSessionAMZ() {
    const amount = localStorage.getItem("CURRENT_AMOUNT");
    const params = new HttpParams()
      .set("amount", amount)
      .set("checkoutSessionId", this.amazonCheckoutSessionId);
    this.http
      .post<AMZUpdateCheckoutModel>(webService.UpdateCheckoutSessionAMZ, params)
      .subscribe((root) => {
        if (root.code === 0) {
          if (this.requestType == "2") {
            this.tipDJ();
          } else {
            this.updateRequestASong();
          }
          this.toastr.success(`Tip successfully sent to ${this.djName}!`);
          this.router.navigate([], {
            queryParams: { amazonCheckoutSessionId: null },
            queryParamsHandling: "merge",
          });
        } else {
          this.toastr.error(
            "Error",
            "Something went wrong, please check your credit/debit card information and try again."
          );
        }
      });
  }

  ngOnInit(): void {
    this.initForm();
    this.getConfig();
    this.playlists = [];
    this.songs = [];
    this.events = [];
    this.shareLink = "https://ujay.com" + this.router.url;
    this.analytics.logEvent("Web_OpenPage");
  }

  ngAfterViewInit(): void {
    this.checkFirstTime();
    this.handlePayment();
    this.handleModal();
    this.timeinterval = setInterval(this.updateClock, 1000);
  }

  btnUsernameSkipClicked() {
    this.usernameModal.hide();
    this.handleCheckCountdown();
  }

  openSearchLocation() {
    if (this.sessionType != 3) {
      this.askLocationModal.show();
    }
  }

  updateClock() {
    var t = Date.parse(Date());
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);

    var remainMinutes;
    var remainSeconds = 60 - seconds;
    if (minutes >= 0 && minutes < 19) {
      remainMinutes = 19 - minutes;
    } else if (minutes >= 20 && minutes < 39) {
      remainMinutes = 39 - minutes;
    } else if (minutes >= 40 && minutes < 59) {
      remainMinutes = 59 - minutes;
    } else {
      remainMinutes = 0;
    }

    if (remainMinutes <= 0 && remainSeconds <= 1) {
      window.postMessage(`REFRESH_TICKER`);
    }

    window.postMessage(`${remainMinutes} mins ${remainSeconds} secs`);
  }

  getConfig() {
    this.http.get<RootConfigModel>(webService.GetConfig).subscribe((root) => {
      if (root.code === 0) {
        this.config = root.data;
        if (this.config.hasSponsor === "1") {
          this.hasSponsor = true;
        } else {
          this.hasSponsor = false;
        }
      } else {
        this.toastr.error("Error", root.msg);
      }
    });
  }

  getSession(isFirst: boolean) {
    this.spinner.show();
    this.events = [];
    const param =
      "?latitude=" + this.lat + "&longitude=" + this.lng + "&filterType=1";
    this.http
      .get<RootEventModel>(webService.getEvent + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          if (root.data.length === 0) {
            this.sessionType = 3;
            this.isKaraoke = true;
            this.GetSongRequestByStandard();
            this.GetSpotifyPlaylist();
            this.GetSongRequestTicker();
            this.locationModal.show();
          } else {
            this.sessionType = 1;
            this.findLocationTitle = "Find Nearby Session";
            this.findLocationDes =
              "Tell us your current location or a location near the session you’re looking for.";
            if (!isFirst) {
              this.locationModal.show();
              if (this.sessionType != 3) {
                this.askLocationModal.show();
              }
              root.data.forEach((element) => {
                this.events.push(element);
              });
            } else {
              this.key = root.data[0].key;
              this.playlists = [];
              this.songs = [];
              this.router.navigate([""], { queryParams: { key: this.key } });
            }
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  showUsernameModal() {
    this.requesterName = localStorage.getItem("username");
    if (
      this.requesterName === "" ||
      this.requesterName === null ||
      this.requesterName === "null" ||
      this.requesterName === undefined
    ) {
      this.requesterName = "";
      this.usernameModal.show();
    } else {
      this.handleCheckCountdown();
    }
  }

  searchSongKeywordChanged(q: string) {
    if (q === "") {
      this.searchSongs = [];
      return;
    }
    let access_token = localStorage.getItem("SPOTIFY_TOKEN");
    if (access_token == null) {
      this.spotify.getSpotifyToken().subscribe((result) => {
        access_token = result.access_token;
        localStorage.setItem("SPOTIFY_TOKEN", result.access_token);
        this.handleSearchSong(q, access_token);
      });
    } else {
      this.handleSearchSong(q, access_token);
    }
  }

  handleSearchSong(q: string, access_token: string) {
    this.spotify.searchSong(q, access_token).subscribe((result) => {
      this.searchSongs = [];
      result.tracks.items.forEach((track) => {
        let artistName = "";
        let imageUrl = "";
        if (track.album.images != null && track.album.images.length > 0) {
          imageUrl = track.album.images[0].url;
        }
        track.artists.forEach((artist) => {
          artistName = artist.name + ",";
        });

        artistName = artistName.slice(0, -1);
        track.imageUrl = imageUrl;
        track.artistName = artistName;
        this.searchSongs.push(track);
      });
    });
  }

  requestStandardSong(
    firstName: string,
    lastName: string,
    songKey: string,
    songName: string,
    songArtist: string,
    songThumb: string,
    packageName: string,
    packageId: string,
    requestValue: string,
    requestType: string,
    latitude: string,
    longitude: string
  ) {
    if (!latitude)
      latitude = "0";
    if (!longitude)
      longitude = "0";

    if (!this.addressName) {
      this.addressName = "";
    }

    if (!this.currentPlaceId) {
      this.currentPlaceId = "";
    }

    var uuid = this.generateUUID();

    const params = new HttpParams()
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("songKey", songKey)
      .set("songName", songName)
      .set("songArtist", songArtist)
      .set("songThumb", songThumb)
      .set("packageName", packageName)
      .set("packageId", packageId)
      .set("requestValue", requestValue)
      .set("requestType", requestType)
      .set("latitude", latitude)
      .set("longitude", longitude)
      .set("businessName", this.addressName)
      .set("address", this.address)
      .set("placeId", this.currentPlaceId)
      .set("uuid", uuid);

    this.spinner.show();

    this.http
      .post(webService.requestStandardSong, params)
      .subscribe((root: RequestedModel) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.currentRequestedKey = root.data;
          this.GetSongRequestByStandard();
          this.GetSpotifyPlaylist();
        }
      });

    this.analytics.logEvent("Web_RequestSong");
  }

  getSessionByUser(uid: string, isFirst: boolean) {
    this.spinner.show();
    this.events = [];
    const param = "?uid=" + uid;
    this.http
      .get<RootEventModel>(webService.GetEventByUser + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          if (root.data.length > 0) {
            this.findLocationTitle = "Find Nearby Session";
            this.findLocationDes =
              "Tell us your current location or a location near the session you’re looking for.";
            this.key = root.data[0].key;
            this.playlists = [];
            this.songs = [];
            this.getSessionDetail(root.data[0].key);
          } else {
            if (this.sessionType != 3) {
              this.findLocationTitle = "No Nearby Session";
              this.findLocationDes =
                "Tell us as a new location to search for sessions.";
              this.askLocationModal.show();
            }
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  getSessionDetail(key: string) {
    this.spinner.show();
    this.playlists = [];
    const param = "?eventKey=" + key;
    this.http
      .get<EventDetailsModel>(webService.getEventDetail + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.djUid = root.event.uid;
          this.event = root.event;
          if (root.event.isKaraoke == "1") {
            this.sessionType = 2;
            this.isKaraoke = true;
          } else {
            this.sessionType = 1;
            this.isKaraoke = false;
          }
          if (this.event.isCollectPhoneNumbers == "1") {
            this.isCollectPhoneNumbers = true;
          } else {
            this.isCollectPhoneNumbers = false;
          }

          if (this.event.isCollectEmails == "1") {
            this.isCollectEmails = true;
          } else {
            this.isCollectEmails = false;
          }
          this.djName = root.event.djName;
          this.hasSponsor = false;
          this.config = {
            img_item_01:
              "https://firebasestorage.googleapis.com/v0/b/mejay-1542341684378.appspot.com/o/img_item_1.png?alt=media&token=4c1e8c27-c7c0-4551-97f2-e958625ac335",
            img_item_02:
              "https://firebasestorage.googleapis.com/v0/b/mejay-1542341684378.appspot.com/o/img_item_2.png?alt=media&token=359c3ea2-8a59-4db9-8eab-409ff91c8177",
            img_item_03:
              "https://firebasestorage.googleapis.com/v0/b/mejay-1542341684378.appspot.com/o/img_item_3.png?alt=media&token=5591ce8e-2d96-4772-b1ee-03515fdb8217",
            item: "",
            img_reward_01: "",
            img_reward_02: "",
            img_reward_03: "",
            hasSponsor: "",
          };
          this.profileUrl = root.event.profileUrl;
          if (
            this.profileUrl === "" ||
            this.profileUrl === "null" ||
            typeof this.profileUrl === "undefined"
          ) {
            this.profileUrl = "../../assets/img/ic_default_user.png";
          }

          if (
            this.event.venue === "" ||
            typeof this.event.venue === "undefined"
          ) {
            this.appService.Venue = " UNKNOWN";
          } else {
            this.appService.Venue = " " + root.event.venue;
          }
          let i = 0;
          root.data.forEach((element) => {
            element.index = i;
            this.playlists.push(element);
            i++;
          });

          switch (this.sessionType) {
            case 1:
            case 2:
              this.dJSessionComponent.setPlaylist(this.playlists);
              if (this.playlists.length > 0) {
                this.getPlaylistDetail(this.key, this.playlists[0].key, this.playlists[0].title);
              }
              if (this.sessionType == 2) {
                this.GetSongRequestByKaraokeEvent();
              }
              break;
            case 3:

              break;
          }
        } else {
          this.toastr.error("Error", "Something went wrong, please try again");
        }
      });
  }

  getPlaylistDetail(eventKey: string, playlistKey: string, playlistTitle: string) {
    $("#" + playlistKey).css("border", "4px solid #53B1C0");
    switch (this.sessionType) {
      case 1:
      case 2:
        this.dJSessionComponent.selectedPlaylistTitle = playlistTitle;
        break;
      case 3:

        break;
    }
    this.spinner.show();
    this.songs = [];
    const param = "?eventKey=" + eventKey + "&playlistKey=" + playlistKey;
    this.http
      .get<RootSongModel>(webService.getPlaylistDetail + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          root.data.forEach((element) => {
            element.IsAd = false;
            element.Enable = true;
            this.songs.push(element);
          });
          switch (this.sessionType) {
            case 1:
            case 2:
              this.dJSessionComponent.songs = this.songs;
              if (this.sessionType == 2) {
                this.GetSongRequestByKaraokeEvent();
              }
              break;
            case 3:

              break;
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  handleModal() {
    this.searchSongModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1040)
          .addClass("modal-stack");
      }, 0);
    });

    this.checkingRequestModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1042)
          .addClass("modal-stack");
      }, 0);
    });

    this.tipModalComponent.tipModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1042)
          .addClass("modal-stack");
      }, 0);
    });

    this.searchSongModal.onHide.subscribe(() => {
      this.searchKeyword = "";
      this.searchSongs = [];
    });

    this.checkingRequestModal.onHide.subscribe(() => {
      this.isRunning = false;
      this.progressValue = 3;
    });

    this.locationModal.onHide.subscribe(() => {
      this.events = [];
    });
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  onShoutoutChange(value: string): void {
    this.shoutoutMessage = value;
  }

  onRequesterNameChange(value: string): void {
    this.requesterName = value;
  }

  btnUsernameDoneClicked() {
    if (this.requesterName.trim() !== "") {
      localStorage.setItem("username", this.requesterName.trim());
      this.usernameModal.hide();
      this.handleCheckCountdown();
    } else {
      this.requesterName = "";
      this.toastr.error(
        "Error",
        "A name is required. You can make one up. Just remember it."
      );
    }
  }

  btnSearchSongClicked() {
    this.analytics.logEvent("Web_Btn_Search");
    this.searchSongModal.show();
    setTimeout(function () {
      document.getElementById("searchInput").focus()
    }, 2)
  }

  btn250Clicked() {
    this.analytics.logEvent("Web_Btn_Tip_1");
    this.amount = 500;
    this.tipModalComponent.tipModal.hide();
    this.updateApplePayPrice();
    this.CreateCheckoutSessionAMZ();
    this.paymentModal.show();
  }

  btn500Clicked() {
    this.analytics.logEvent("Web_Btn_Tip_2");
    this.amount = 1000;
    this.tipModalComponent.tipModal.hide();
    this.updateApplePayPrice();
    this.paymentModal.show();
  }

  btn1000Clicked() {
    this.analytics.logEvent("Web_Btn_Tip_3");
    this.amount = 1500;
    this.tipModalComponent.tipModal.hide();
    this.updateApplePayPrice();
    this.CreateCheckoutSessionAMZ();
    this.paymentModal.show();
  }

  btnShoutoutClicked() {
    this.analytics.logEvent("Web_Btn_Shoutout");
    this.getDisplayName();
    this.shoutoutModal.show();
  }

  btnTipClicked() {
    this.tipDJModalComponent.djEmail = this.event.djEmail;
    this.tipDJModalComponent.updateItemIcon();
    this.tipDJModalComponent.tipDJModal.show();
  }

  TipDJModalBtnClicked(event: string) {
    switch (event) {
      case "btnTipDJ1Clicked":
        this.requestType = '2';
        this.amount = 500;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJ2Clicked":
        this.requestType = '2';
        this.amount = 1000;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJ3Clicked":
        this.requestType = '2';
        this.amount = 1500;
        this.tipDJModalComponent.tipDJModal.hide();
        this.updateApplePayPrice();
        this.CreateCheckoutSessionAMZ();
        this.paymentModal.show();
        break;
      case "btnTipDJCustomTipClicked":
        if (this.tipDJModalComponent.customTip > 0) {
          this.requestType = '2';
          this.amount = this.tipDJModalComponent.customTip * 100;
          this.tipDJModalComponent.tipDJModal.hide();
          this.updateApplePayPrice();
          this.CreateCheckoutSessionAMZ();
          this.paymentModal.show();
        } else {
          this.toastr.show("Please enter valid amount");
        }
        break;
    }
  }

  getDisplayName() {
    this.requesterName = localStorage.getItem("username");
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = this.authAppService.displayName;
    }
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = "";
    }
  }

  btnRequestShoutoutClicked() {
    if (this.shoutoutMessage === "") {
      this.toastr.show("Shoutout message is empty");
      return;
    }
    if (this.shoutoutMessage.length < 10) {
      this.toastr.show("Shoutouts require a minimum of 10 characters");
      return;
    }
    localStorage.setItem("username", this.requesterName);
    this.shoutoutModal.hide();

    this.requestType = "3";
    this.requestSong(
      "",
      "",
      this.requesterName,
      "",
      "",
      this.event.key,
      this.event.eventName,
      "",
      "",
      "",
      "",
      "$0.00",
      "0",
      this.event.uid,
      this.event.djEmail,
      this.shoutoutMessage,
      this.requestType
    );
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.playlists = [];
    this.songs = [];
    this.events = [];
  }

  onVenueSelect(item: EventModel): void {
    this.key = item.key;
    this.locationModal.hide();
    this.playlists = [];
    this.songs = [];
    this.locations = [];
    this.router.navigate([""], { queryParams: { key: item.key } });
  }

  onLocationSelect(item: any): void {
    this.appService.Venue = item.displayName.text;
    this.addressName = item.displayName.text;
    this.address = item.formattedAddress;
    this.currentPlaceId = item.id;
    this.lat = item.location.lat;
    this.lng = item.location.lng;
    Helpers.saveLocation(this.lat, this.lng);
    localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
    localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
    this.GetSongRequestByStandard();
    this.standardSessionComponent.subscribeFirestore();
    this.GetSpotifyPlaylist();
    this.GetSongRequestTicker();
    this.locationModal.hide();
  }

  btnLocationContinueClicked() {
    this.GetSongRequestByStandard();
    this.standardSessionComponent.subscribeFirestore();
    this.GetSpotifyPlaylist();
    this.GetSongRequestTicker();
    this.locationModal.hide();
  }

  btnPlaylistClicked() {
    var playlistId = localStorage.getItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID);
    var uris = [];
    this.songs.forEach(s => {
      uris.push(s.Uri);
    });
    this.appService.updateSpotifyPlaylist.next(uris.toString());
    if (!playlistId) {
      this.appService.getSpotifyPlaylist.next();
    } else {
      window.open("https://open.spotify.com/playlist/" + playlistId, "_blank");
    }
  }

  onPlaylistSelect(item: PlaylistModel): void {
    this.playlists.forEach(function (value) {
      $("#" + value.key).css("border", "4px solid #ffffff00");
    })
    this.getPlaylistDetail(this.key, item.key, item.title);
  }

  canRequestASong() {
    if (this.event.isKaraoke !== "1") {
      return true;
    }
    let latestRequest = localStorage.getItem(Constants.SETTINGS_LATEST_REQUEST);
    if (!latestRequest) {
      latestRequest = "0";
    }
    const now = Date.now();
    const limitTime = 3 * 60000;
    const timeFromLastRequest = now - Number(latestRequest);
    if (timeFromLastRequest > limitTime) {
      return true;
    }
    const timeLeft = limitTime - timeFromLastRequest;
    this.toastr.error("Make Another Request in " + Helpers.msToTime(timeLeft));
    return false;
  }

  onSpotifySongSelect(item: SpotifyTrackModel) {
    if (this.sessionType == 1 || this.sessionType == 2) {
      if (!this.canRequestASong()) {
        return;
      }
    }

    let artists = "";
    item.artists.forEach((a) => {
      artists += a.name + ",";
    });
    if (artists !== "") {
      artists = artists.substring(0, artists.length - 1);
    }
    this.currentSongThumb = item.imageUrl;
    this.currentSongTitle = item.name;
    this.currentSongArtist = artists;
    this.currentSongKey = item.uri;
    this.progressValue = 3;
    this.isRunning = true;
    this.searchKeyword = "";
    this.searchSongModal.hide();
    this.showUsernameModal();
  }

  onSongSelect(item: SongModel): void {
    if (!this.canRequestASong()) {
      return;
    }
    if (item.key == "")
      return;
    if (this.isKaraoke) {
      if (!item.Enable) {
        return;
      }
    }
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.key;
    this.progressValue = 3;
    this.isRunning = true;
    this.showUsernameModal();
  }

  onStandardSongSelect(item: SongModel): void {
    if (item.key == "")
      return;

    this.currentSongSelected = item;
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.key;
    this.currentAppleMusic = item.AppleMusic;
    this.currentRequestedBy = "Requested by " + item.Requester;

    this.upvoteModal.show();
  }

  handleCheckCountdown() {
    setTimeout(() => {
      this.progressValue--;
      if (this.progressValue < 0) {
        this.progressValue = 0;
        this.isRunning = false;
        this.checkingRequestModal.hide();
        this.requestType = "1";
        this.countSongRequest++;
        this.getDisplayName();
        if (this.sessionType != 3) {
          this.requestSong(
            "",
            "",
            this.requesterName,
            "",
            "",
            this.event.key,
            this.event.eventName,
            this.currentSongKey,
            this.currentSongTitle,
            this.currentSongArtist,
            this.currentSongThumb,
            "$0.00",
            "0",
            this.event.uid,
            this.event.djEmail,
            this.shoutoutMessage,
            this.requestType
          );
        } else {
          this.requestStandardSong(
            this.requesterName,
            "",
            this.currentSongKey,
            this.currentSongTitle,
            this.currentSongArtist,
            this.currentSongThumb,
            "$0.00",
            "0",
            this.shoutoutMessage,
            this.requestType,
            this.lat,
            this.lng
          );
        }
      }
      if (this.isRunning) {
        this.handleCheckCountdown();
      }
    }, 1000);
    this.checkingRequestModal.show();
  }

  btnCancelRequestClicked() {
    this.isRunning = false;
    this.progressValue = 3;
    this.checkingRequestModal.hide();
  }

  btnCancelPaymentClicked() {
    this.paymentModal.hide();
  }

  btnMaybeLaterClicked() {
    this.tipModalComponent.tipModal.hide();
  }

  btnCloseUpvoteClicked() {
    this.upvoteModal.hide();
  }

  btnRequestRadiusClicked() {
    this.menuModal.hide();
    this.radiusModal.show();
  }

  btnHelpClicked() {
    this.menuModal.hide();
    this.helpModal.show();
  }

  btnHelpRequestClicked() {
    this.helpModal.hide();
    this.initStandardSession();
    this.getUserLocation(true, false);
  }

  btnContactUsClicked() {
    window.open("https://ujayapp.com/#contactus", "_blank");
  }

  btnGetDJClicked() {
    window.open("https://apps.apple.com/us/app/ujay/id1447792017", "_blank");
  }

  btnDJResourcesClicked() {
    window.open("https://ujayapp.com", "_blank");
  }

  btnUpvoteClicked() {
    this.spinner.show();
    var uuid = this.generateUUID();

    const params = new HttpParams()
      .set("songKey", this.currentSongSelected.TrackId)
      .set("uuid", uuid)
      .set("lat", this.lat)
      .set("lng", this.lng);
    this.http
      .post<CommonModel>(webService.upvote, params)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.currentSongSelected.NoRequested++;
          this.currentSongSelected.Upvoted = true;
          this.standardSessionComponent.rearrange();
          this.upvoteModal.hide();
        } else if (root.code == 1) {
          this.toastr.error("Error", "Something went wrong");
        } else {
          this.toastr.info("Info", root.msg);
        }
      });
  }

  btnSpotifyClicked() {
    if (this.currentSongSelected == null)
      return;
    window.open(this.currentSongSelected.Uri, "_blank");
  }

  btnAppleMusicClicked() {
    if (this.currentSongSelected == null)
      return;
    window.open(this.currentSongSelected.AppleMusic, "_blank");
  }

  StandardSessionBtnClicked(event: string) {
    switch (event) {
      case "btnSearchSongClicked":
        this.btnSearchSongClicked();
        break;
    }
  }

  onRequestSongDonated(): void {

  }
  onUpvoteDonated(): void {

  }

  GetSongRequestTicker() {
    this.spinner.show();
    const param = "?latitude=" + this.lat + "&longitude=" + this.lng;
    this.http
      .get<RootSongRequestModel>(webService.GetSongRequestByStandardForTicker + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          var data = [];
          const s = { requestType: "3", requestValue: "CURRENT PLAYLIST " } as SongRequestModel;
          data.push(s);
          root.data.forEach(element => {
            data.push(element);
          });
          this.songRequestedTicker(data);
        }
      });
  }

  songRequestedTicker(data) {
    $("#tickerRefreshPlaylist").css(
      "height", "50px"
    );
    if (data.length <= 1) {
      $("#marquee").css(
        "height", "0px"
      );
      $("#footer").css(
        "height", "50px"
      );
      return;
    }

    $("#marquee").css(
      "height", "50px"
    );
    $("#footer").css(
      "height", "100px"
    );

    const speed = (data.length + 1) * 3;
    $("#marqueeP").css(
      "animation",
      `sidescroll ${speed}s linear infinite`
    );

    this.marquee.nativeElement.innerHTML = "";

    data.forEach((element) => {
      if (
        element.requestType == "1" ||
        element.requestType == "2" ||
        element.requestType == "3"
      ) {
        let name: string;
        if (
          element.firstName === "Anonymous" ||
          element.firstName === ""
        ) {
          name = "Someone";
        } else {
          name = element.firstName;
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const img = element.songThumb;
          const htmlImg =
            '<img alt="" class="songThumbMarquee center-crop" src="' +
            img +
            '"' +
            "</img>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            htmlImg
          );
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const html3 =
            '<a class="requestBoldSpan">' + element.songName + "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );

          if (name !== "Someone") {
            const html2 = '<a class="requestedSpan">requested by</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 = '<a class="requestBoldSpan">' + name + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          } else {
            const html2 = '<a class="requestedSpan">-</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 =
              '<a class="requestBoldSpan">' + element.songArtist + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          }
        } else if (element.requestType === "3") {
          const html3 =
            '<a class="requestBoldSpan">' +
            element.requestValue +
            "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );
        }
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          '<a class="bulletSpan">&#8226;</a>'
        );
      }
    });
  }

  googlePlaceSelected(): void {
    this.GetSongRequestTicker();
  }
}
