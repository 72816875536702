import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { SongModel } from "../../../models/SongModel";
import { shareReplay } from "rxjs/operators";
import { Enumerable } from 'sharp-collections';
import { AngularFirestore } from '@angular/fire/firestore';
import * as geofirestore from 'geofirestore';
import firebase from 'firebase/app';
import { Constants } from "../../../helpers/Constants";
import { Helpers } from "../../../helpers/Helpers";
import { AppService } from "../../../services/app.service";

@Component({
  selector: "StandardSession",
  templateUrl: "./standard.component.html",
})
export class StandardSessionComponent implements OnInit, OnDestroy {

  @Output() btnClicked = new EventEmitter<string>();
  @Output() standardSongItemClicked = new EventEmitter<SongModel>();

  songs = [];
  noLocation = false;
  collectionSubscribe: any;

  constructor(private firestore: AngularFirestore,
    public appService: AppService) {

  }

  ngOnDestroy(): void {
    this.songs.forEach(s => {
      s.FirebaseSubscribe.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.subscribeFirestore();
  }

  subscribeFirestore() {
    var placeId = localStorage.getItem(Constants.SETTINGS_PLACEID);
    this.collectionSubscribe = this.firestore.collection('tb_standard_request', ref => ref
      .where('placeId', '==', placeId)
      .where('enable', '==', "0"))
      .snapshotChanges()
      .pipe(shareReplay());

    this.collectionSubscribe.subscribe(data => {
      const enumerable = Enumerable.from(this.songs);
      var changedData = data as any;
      var uuid = Helpers.generateUUID();
      changedData.forEach((item) => {
        var data = item.payload.doc.data();
        var foundSong = enumerable.where(x => x.key == item.payload.doc.id).firstOrDefault();
        if (!foundSong) {
          var upvoted = false;
          if (data.uuids.includes(uuid)) {
            upvoted = true;
          }
          const s = {
            key: data.key, Uri: data.songKey,
            TrackId: data.songKey, Artist: data.songArtist,
            Thumb: data.songThumb, Title: data.songName,
            Enable: true, NoRequested: data.noRequested,
            Requester: data.firstName + " " + data.lastName,
            AppleMusic: data.link_apple_music,
            UUID: data.uuid, Upvoted: upvoted, Time: data.time
          } as SongModel;
          this.songs.push(s);
          this.subcribeSong(s);
        }
      })
      this.rearrange();
    });
  }

  rearrange(): void {
    var tempSongs = this.songs;
    this.songs = [];
    tempSongs.sort((a, b) => a.Time - b.Time);
    tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);
    tempSongs.forEach(s => {
      this.songs.push(s);
    });
  }

  updateSongs(songs: string[]): void {
    this.songs = songs;
    this.songs.forEach(s => {
      this.subcribeSong(s);
    });
  }

  subcribeSong(s) {
    var firebaseSubscribe = this.firestore.collection('tb_standard_request')
      .doc(s.key)
      .snapshotChanges()
      .pipe(shareReplay());
    s.FirebaseSubscribe = firebaseSubscribe;

    const enumerable = Enumerable.from(this.songs);
    firebaseSubscribe.subscribe(data => {
      var changedData = data as any;
      var songData = changedData.payload.data();
      if (songData.enable == "2") {
        var foundSong = enumerable.where(x => x.key == changedData.payload.id).firstOrDefault();
        if (foundSong) {
          var index = this.songs.indexOf(foundSong);
          this.songs.splice(index, 1);
        }
      } else {
        var foundSong = enumerable.where(x => x.key == changedData.payload.id).firstOrDefault();
        if (foundSong) {
          foundSong.NoRequested = changedData.payload.data().noRequested;
          this.rearrange();
        }
      }
    });
  }

  onSongSelect(item: SongModel): void {
    if (item.key == "")
      return;
    this.standardSongItemClicked.emit(item);
  }

  btnSearchSongClicked() {
    this.btnClicked.emit("btnSearchSongClicked");
  }
}
