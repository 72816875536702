import { HttpClient, HttpParams } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { WebService } from "../../services/WebService";
import { Constants } from "../../helpers/Constants";
import { webService } from "../../webServices/api";
import { CommonModel } from "../../models/CommonModel";
import { AMZCheckoutModel } from "../../models/AMZCheckoutModel";
import { GoogleComponent } from "./google.component";

declare var $: any;
declare var window: any;
declare let paypal: any;

export abstract class PaymentComponent extends GoogleComponent {

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public authAppService: AuthService,
    public appService: AppService,
    public location: Location,
  ) {
    super(
      router,
      route,
      http,
      webService,
      toastr,
      spinner,
      authAppService,
      appService,
      location
    );
  }

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");
    return card;
  }

  async initializeApplePay(payments) {
    const paymentRequest = this.buildPaymentRequest(payments);
    const applePay = await payments.applePay(paymentRequest);
    return applePay;
  }

  buildPaymentRequest(payments) {
    return payments.paymentRequest({
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: (this.amount / 100).toString(),
        label: "TIP",
      },
    });
  }

  async updateApplePayPrice() {
    if (!this.isSafari()) {
      return;
    }
    try {
      this.applePay = await this.initializeApplePay(this.squarePayments);
    } catch (e) {
      console.error("Initializing Apple Pay failed", e);
    }
  }

  CreateCheckoutSessionAMZ() {
    const amzAmount = (this.amount / 100).toString();
    const params = new HttpParams()
      .set("currentPageUrl", window.location.origin + "/?key=" + this.key)
      .set("amount", amzAmount);

    localStorage.setItem("CURRENT_AMOUNT", amzAmount);

    this.http
      .post<AMZCheckoutModel>(webService.CreateCheckoutSessionAMZ, params)
      .subscribe((root) => {
        if (root.code === 0) {
          this.payloadJSON = root.payloadJSON;
          this.signature = root.signature;

          const head = document.getElementById("divPayment");
          const _js2 = document.createElement("script");
          _js2.type = "text/javascript";
          _js2.charset = "utf-8";
          _js2.appendChild(
            document.createTextNode(`
                  {
                    amazon.Pay.renderButton('#AmazonPayButton', {
                      merchantId: 'AVM84QRTVPHAO',
                      ledgerCurrency: 'USD',
                      sandbox: false,
                      checkoutLanguage: 'en_US',
                      productType: 'PayAndShip',
                      placement: 'Checkout',
                      buttonColor: 'Gold',
                      createCheckoutSessionConfig: {
                          payloadJSON: '${this.payloadJSON}',
                          signature: '${this.signature}',
                          publicKeyId: 'AGWWY2IDVJE6PCTMTOG6VGKZ'
                      }
                    });
                  }
                `)
          );
          head.appendChild(_js2);

          this.paymentModal.show();
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  handlePayment() {
    this.loadExternalScript(
      // AVIt7_FLRLUeLcuneoANM5vF6XtuQe-XCwJYD1UfyTFtWXZU3VbTQw_K2143d6SPjBaCHxtXJsUd5_bx / sandbox
      // AThUyBi-1WaJ6EZjmlIVhrHqhwzmFcg_CZws-HcJOT7RrKdvdCxycKoJ2jnBuD1uFnx52V8OjOT9dZme / production
      "https://www.paypal.com/sdk/js?client-id=AThUyBi-1WaJ6EZjmlIVhrHqhwzmFcg_CZws-HcJOT7RrKdvdCxycKoJ2jnBuD1uFnx52V8OjOT9dZme&disable-funding=card"
    ).then(() => {
      paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: this.amount / 100 + "",
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              this.handleAferPaid();
            });
          },
        })
        .render("#paypal-container");
    });

    this.setupSquareUp();
  }

  setupSquareUp() {
    let srcSquareup = "";
    const srcSquareupSandbox = "https://sandbox.web.squarecdn.com/v1/square.js";
    const srcSquareupProd = "https://web.squarecdn.com/v1/square.js";

    let squareupAppId = "";
    const squareupAppIdSandbox = "sandbox-sq0idb-MqwrP9RqO6HgJ4hzM8UjbQ";
    const squareupAppIdProd = "sq0idp-kwKodbH7r-wzlxf3vMpNSA";

    if (Constants.IsSanbox) {
      srcSquareup = srcSquareupSandbox;
      squareupAppId = squareupAppIdSandbox;
    } else {
      srcSquareup = srcSquareupProd;
      squareupAppId = squareupAppIdProd;
    }

    this.loadExternalScript(srcSquareup).then(async () => {
      try {
        this.squarePayments = window.Square.payments(
          squareupAppId,
          "BD2KNE5V9KS0F"
        );
      } catch {
        return;
      }
      let card;
      try {
        card = await this.initializeCard(this.squarePayments);
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }

      const cardButton = document.getElementById("card-button");
      cardButton.addEventListener("click", async (event) => {
        await this.handlePaymentMethodSubmission(event, card);
      });

      await this.setupApplePay();
    });
  }

  async setupApplePay() {
    if (!this.isSafari()) {
      return;
    }
    try {
      this.applePay = await this.initializeApplePay(this.squarePayments);
    } catch (e) {
      document.getElementById("apple-pay-button").style.display = "none";
      console.error("Initializing Apple Pay failed", e);
    }

    if (this.applePay) {
      const applePayButton = document.getElementById("apple-pay-button");
      applePayButton.addEventListener("click", async (event) => {
        await this.handlePaymentMethodSubmission(event, this.applePay);
      });
    }
  }

  async handlePaymentMethodSubmission(event, paymentMethod) {
    event.preventDefault();
    try {
      const token = await this.tokenize(paymentMethod);
      this.checkoutSquareUp(token);
    } catch (e) {
      console.error(e.message);
    }
  }

  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  checkoutSquareUp(nonce: string) {
    this.spinner.show();
    let urlPath = "";
    if (Constants.IsSanbox) {
      urlPath = webService.ProcessPaymentSandbox;
    } else {
      urlPath = webService.ProcessPayment;
    }
    const params = new HttpParams()
      .set("amount", (this.amount / 100).toString())
      .set("nonce", nonce);
    this.http.post<CommonModel>(urlPath, params).subscribe((root) => {
      this.paymentModal.hide();
      this.spinner.hide();
      if (root.code === 0) {
        this.handleAferPaid();
      } else {
        this.toastr.error(
          "Error",
          "Something went wrong, please check your credit/debit card information and try again."
        );
      }
    });
  }

  handleAferPaid() {
    if (this.sessionType == 1 || this.sessionType == 2) {
      if (this.requestType == "2") {
        this.tipDJ();
      } else {
        this.updateRequestASong();
      }
    } else {
      if (this.userRequestType == Constants.REQUEST_TYPE_SONG_REQUEST) {
        this.toastr.success("Your donation has been received. You are in the queue to perform.");
        this.onRequestSongDonated();
      } else if (this.userRequestType == Constants.REQUEST_TYPE_UPVOTE) {
        this.toastr.success("Your donation has been received.");
        this.onUpvoteDonated();
      }
    }
  }

  abstract tipDJ(): void;
  abstract updateRequestASong(): void;
  abstract onRequestSongDonated(): void;
  abstract onUpvoteDonated(): void;
}