import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AMZCheckoutModel } from "../models/AMZCheckoutModel";
import { RootSongRequestModel } from "../models/SongRequestModel";
import { webService } from "../webServices/api";

@Injectable()
export class WebService {
  constructor(private http: HttpClient) {}

  async getSongRequestByEvent(event: string): Promise<RootSongRequestModel> {
    const param = "?eventKey=" + event;
    const root = await this.http
      .get<RootSongRequestModel>(webService.GetSongRequestByKaraokeEvent + param)
      .toPromise();
    return root;
  }

  async CreateCheckoutSessionAMZ(
    key: string,
    amount: number
  ): Promise<AMZCheckoutModel> {
    const amzAmount = (amount / 100).toString();
    const params = new HttpParams()
      .set("currentPageUrl", window.location.origin + "/?key=" + key)
      .set("amount", amzAmount);

    localStorage.setItem("CURRENT_AMOUNT", amzAmount);

    const root = await this.http
      .post<AMZCheckoutModel>(webService.CreateCheckoutSessionAMZ, params)
      .toPromise();

    return root;
  }
}
