import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AZPComponent } from "./views/azpResult/azp.component";
import { BPMComponent } from "./views/bpm/bpm.component";


import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { GenreComponent } from "./views/genre/genre.component";
import { LoginComponent } from "./views/login/login.component";
import { MoodComponent } from "./views/mood/mood.component";
import { PartyDashboardComponent } from "./views/partyDashboard/partyDashboard.component";
import { RegisterComponent } from "./views/register/register.component";
import { FristFridayComponent } from "./views/firstFriday/fristfriday.component";
import { DetailComponent } from "./views/detail/detail.component";

export const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "register",
    component: RegisterComponent
  },
  {
    path: '',
    data: {
      title: "UJAY",
    },
    children: [
      // {
      //   path: '',
      //   component: DetailComponent,
      // },
      {
        path: "",
        component: FristFridayComponent,
      },
      {
        path: "genre",
        component: GenreComponent,
      },
      {
        path: "mood",
        component: MoodComponent,
      },
      {
        path: "bpm",
        component: BPMComponent,
      },
      {
        path: "partyDashboard",
        component: PartyDashboardComponent
      }
    ]
  },
  {
    path: "azp",
    component: AZPComponent,
    data: {
      title: "UJAY",
    },
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
