import { HttpClient, HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { Location } from '@angular/common';
import { ModalDirective } from "ngx-bootstrap/modal";
import { webService } from "../../webServices/api";
import { Constants } from "../../helpers/Constants";
import { RequestedModel } from "../../models/RequestedModel";
import { WebService } from "../../services/WebService";
import { Helpers } from "../../helpers/Helpers";
import { PremiumComponent } from "../base/premium.component";

@Component({
    templateUrl: 'mood.component.html',
})
export class MoodComponent extends PremiumComponent implements OnInit, AfterViewInit {
    @ViewChild('checkingRequestModal') public checkingRequestModal: ModalDirective;

    originMoods = [];
    moods = [];
    currentSelected = "";
    isRunning = false;
    progressValue = 3;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public authAppService: AuthService,
        public appService: AppService,
        public http: HttpClient,
        public webService: WebService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public location: Location
    ) {
        super(
            router,
            route,
            http,
            webService,
            toastr,
            spinner,
            authAppService,
            appService,
            location
          );
    }

    ngOnInit(): void {
        this.initMood();
        this.loadEvent();
    }

    onDestroy(): void {
    
    }
    
    ngAfterViewInit(): void {
        this.handlePayment();
    }

    btnCancelRequestClicked() {
        this.isRunning = false;
        this.progressValue = 3;
        this.checkingRequestModal.hide();
    }

    onSelected(mood: string): void {
        this.progressValue = 3;
        this.isRunning = true;
        this.currentSelected = mood;
        this.checkingRequestModal.show();
        this.handleCheckCountdown(mood);
    }

    initMood() {
        this.originMoods.push("Angry");
        this.originMoods.push("Busy & Frantic");
        this.originMoods.push("Changing Tempo");
        this.originMoods.push("Chasing");
        this.originMoods.push("Dark");
        this.originMoods.push("Dreamy");
        this.originMoods.push("Eccentric");
        this.originMoods.push("Elegant");
        this.originMoods.push("Epic");
        this.originMoods.push("Euphoric");
        this.originMoods.push("Fear");
        this.originMoods.push("Floating");
        this.originMoods.push("Funny");
        this.originMoods.push("Glamorous");
        this.originMoods.push("Happy");
        this.originMoods.push("Heavy & Ponderous");
        this.originMoods.push("Hopeful");
        this.originMoods.push("Laid Back");
        this.originMoods.push("Marching");
        this.originMoods.push("Mysterious");
        this.originMoods.push("Peaceful");
        this.originMoods.push("Quirky");
        this.originMoods.push("Relaxing");
        this.originMoods.push("Restless");
        this.originMoods.push("Romantic");
        this.originMoods.push("Running");
        this.originMoods.push("Sad");
        this.originMoods.push("Scary");
        this.originMoods.push("Sentimental");
        this.originMoods.push("Sexy");
        this.originMoods.push("Smooth");
        this.originMoods.push("Sneaking");
        this.originMoods.push("Suspense");
        this.originMoods.push("Weird");

        this.resetMood();
    }

    resetMood() {
        this.moods = [];
        this.originMoods.forEach(i => {
            this.moods.push(i);
        });
    }

    searchKeywordChanged(q: string) {
        if (q === '') {
            this.resetMood();
            return;
        }

        var result = Helpers.find(this.originMoods, q);
        this.moods = [];
        result.forEach(i => {
            this.moods.push(i);
        });
    }

    handleCheckCountdown(mood: string) {
        setTimeout(() => {
            this.progressValue--;
            if (this.progressValue < 0) {
                this.progressValue = 0;
                this.isRunning = false;
                this.checkingRequestModal.hide();
                this.requestType = '6';
                this.requestMood(
                    '',
                    '',
                    '',
                    '',
                    '',
                    this.event.key,
                    this.event.eventName,
                    '',
                    '',
                    '',
                    '',
                    '$0.00',
                    '0',
                    this.event.uid,
                    this.event.djEmail,
                    mood,
                    this.requestType
                );
            }
            if (this.isRunning) {
                this.handleCheckCountdown(mood);
            }
        }, 1000);
        this.checkingRequestModal.show();
    }

    requestMood(
        uid: string,
        email: string,
        firstName: string,
        lastName: string,
        avatar: string,
        eventKey: string,
        eventName: string,
        songKey: string,
        songName: string,
        songArtist: string,
        songThumb: string,
        packageName: string,
        packageId: string,
        djUid: string,
        djEmail: string,
        shoutoutMessage: string,
        requestType: string
    ) {
        const params = new HttpParams()
            .set('uid', uid)
            .set('email', email)
            .set('firstName', firstName)
            .set('lastName', lastName)
            .set('avatar', avatar)
            .set('eventKey', eventKey)
            .set('eventName', eventName)
            .set('songKey', songKey)
            .set('songName', songName)
            .set('songArtist', songArtist)
            .set('songThumb', songThumb)
            .set('packageName', packageName)
            .set('packageId', packageId)
            .set('djUid', djUid)
            .set('djEmail', djEmail)
            .set('requestValue', shoutoutMessage)
            .set('requestType', requestType);

        this.spinner.show();

        this.http
            .post(webService.userRequest, params)
            .subscribe((root: RequestedModel) => {
                this.spinner.hide();
                if (root.code === 0) {
                    this.currentRequestedKey = root.data;

                    localStorage.setItem(Constants.SETTINGS_CURRENT_REQUESTED_KEY, this.currentRequestedKey);
                    localStorage.setItem(Constants.SETTINGS_REQUEST_TYPE, requestType);
                    localStorage.setItem(Constants.SETTINGS_LATEST_REQUEST, Date.now().toString());

                    if (this.event.isTipping === "1") {
                        this.tipModalComponent.profileUrl = this.event.profileUrl;
                        this.tipModalComponent.djName = this.event.djName;
                        this.tipModalComponent.djEmail = this.event.djEmail;
                        this.tipModalComponent.updateItemIcon();
                        this.tipModalComponent.subTitle = " got your mood request."
                        this.tipModalComponent.tipModal.show();
                    }

                    // this.afterRequestModalComponent.title = "Request Another Mood";

                    this.toastr.success('Information', this.event.djName + ' has received your mood request.');
                } else {
                    this.toastr.error('Error', 'Something went wrong');
                }
            });
    }

    onRequestSongDonated(): void {
        
    }
    onUpvoteDonated(): void {
        
    }
    updateRequestASong(): void {
        
    }
    tipDJ(): void {
        
    }

    googlePlaceSelected(): void {
        
    }
}