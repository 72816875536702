import { HttpClient, HttpParams } from "@angular/common/http";
import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { Constants } from "../../helpers/Constants";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { webService } from "../../webServices/api";
import { EventModel, RootEventModel } from "../../models/EventModel";
import { RootSongRequestModel, SongRequestModel } from "../../models/SongRequestModel";
import { SponsorModel } from "../../models/SponsorModel";
import { WebService } from "../../services/WebService";
import { TipModalComponent } from "./tip-modal/tip-modal.component";
import { SongModel } from "../../models/SongModel";
import { Helpers } from "../../helpers/Helpers";
import { StandardSessionComponent } from "../detail/standard/standard.component";
import { DJSessionComponent } from "../detail/djSession/djsession.component";
import { v4 as uuidv4 } from 'uuid';
import { UpNextComponent } from "../firstFriday/ui/upnext.component";
import { DonateModalComponent } from "./donate-modal/donate-modal.component";
import { PerformanceComponent } from "../firstFriday/ui/performance.component";
import { GeoPosition } from "../../helpers/GeoPosition";

declare var $: any;
declare let paypal: any;
declare var window: any;
declare var googletag: any;
declare let amazon: any;
declare let OffAmazonPayments: any;
declare let loginOptions: any;

@Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {
  @ViewChild("askLocationModal") public askLocationModal: ModalDirective;
  @ViewChild("locationModal") public locationModal: ModalDirective;
  @ViewChild("locationGoogleModal") public locationGoogleModal: ModalDirective;
  @ViewChild("shoutoutModal") public shoutoutModal: ModalDirective;
  @ViewChild("paymentModal") public paymentModal: ModalDirective;
  @ViewChild("marquee") public marquee: ElementRef;

  @ViewChild(TipModalComponent) tipModalComponent: TipModalComponent;
  @ViewChild(DonateModalComponent) donateModalComponent: DonateModalComponent;

  @ViewChild(StandardSessionComponent) standardSessionComponent: StandardSessionComponent;
  @ViewChild(DJSessionComponent) dJSessionComponent: DJSessionComponent;
  @ViewChild(UpNextComponent) upNextComponent: UpNextComponent;
  @ViewChild(PerformanceComponent) performanceComponent: PerformanceComponent;

  sessionType = 1;

  amount: number = 1000;
  squarePayments: any;
  applePay: any;
  events = [];
  songs = [];
  locations = [];
  nearByLocations = [];
  currentRequestedKey = "";
  key: string;
  event: EventModel;
  djName: string;
  requestType = "1";
  hasSponsor: boolean;
  shoutoutSponsor: SponsorModel = {
    id: "",
    title: "",
    subTitle: "",
    thumb: "",
    link: "",
  };
  addressName = "";
  address: any;
  currentPlaceId = "";
  lat: any;
  lng: any;
  noLocation = false;
  requesterName = "";

  searchKeyword: string;

  isKaraoke = false;
  isTipping = true;
  isCollectPhoneNumbers = false;
  isCollectEmails = false;

  userRequestType = ""
  isPerformance = false;

  payloadJSON: string;
  signature: string;

  findLocationTitle = "Find Nearby DJ Session";
  findLocationDes =
    "Tell us your current location or a location near the session you’re looking for.";

  btnWrongVenueClickedEvent: any;

  spotifyPlaylistId = "";

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authAppService: AuthService,
    public appService: AppService,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public location: Location
  ) {

  }

  ngOnDestroy(): void {
    this.btnWrongVenueClickedEvent.unsubscribe();
    this.onDestroy()
  }

  abstract onDestroy(): void

  AfterRequestModalBtnClicked(event: string) {
    switch (event) {
      case "btnYesClicked":
        break;
      case "btnCancelClicked":
        this.location.back();
        break;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadEvent() {
    var key = localStorage.getItem(Constants.EVENT_KEY);
    var eventName = localStorage.getItem(Constants.EVENT_NAME);
    var uid = localStorage.getItem(Constants.EVENT_UID);
    var djEmail = localStorage.getItem(Constants.EVENT_DJEMAIL);
    var djName = localStorage.getItem(Constants.EVENT_DJNAME);
    var profileUrl = localStorage.getItem(Constants.EVENT_PROFILE_URL);
    var venue = localStorage.getItem(Constants.EVENT_VENUE);
    var isKaraoke = localStorage.getItem(Constants.EVENT_IS_KARAOKE);
    var isTipping = localStorage.getItem(Constants.EVENT_IS_TIPPING);
    var isCollectEmails = localStorage.getItem(Constants.EVENT_IS_COLLECTS_EMAILS);
    var isCollectPhoneNumbers = localStorage.getItem(Constants.EVENT_IS_COLLECTS_PHONE_NUMBERS);

    this.event = {
      key: key,
      cover: "",
      eventName: eventName,
      genres: "",
      address: "",
      venue: venue,
      uid: uid,
      djEmail: djEmail,
      djName: djName,
      profileUrl: profileUrl,
      isKaraoke: isKaraoke,
      isTipping: isTipping,
      enable: "",
      isCollectEmails: isCollectEmails,
      isCollectPhoneNumbers: isCollectPhoneNumbers
    };

    if (this.event.venue === "" || typeof this.event.venue === "undefined") {
      this.appService.Venue = " UNKNOWN";
    } else {
      this.appService.Venue = " " + this.event.venue;
    }

    if (isTipping == "1") {
      this.isTipping = true;
    } else {
      this.isTipping = false;
    }
    this.appService.IsTipping = this.isTipping;

    if (this.event.isKaraoke == "1") {
      this.isKaraoke = true;
    } else {
      this.isKaraoke = false;
    }

    if (this.event.isCollectPhoneNumbers == "1") {
      this.isCollectPhoneNumbers = true;
    } else {
      this.isCollectPhoneNumbers = false;
    }

    if (this.event.isCollectEmails == "1") {
      this.isCollectEmails = true;
    } else {
      this.isCollectEmails = false;
    }
  }

  calculateDistanceForGoogleGeo(lat1: any, lng1: any, lat2: any, lng2: any): number {
    function deg2rad(deg) { return deg * (Math.PI / 180); }
    function square(x) { return Math.pow(x, 2); }
    var r = 6371;
    lat1 = deg2rad(lat1);
    lat2 = deg2rad(lat2);
    var lat_dif = lat2 - lat1;
    var lng_dif = deg2rad(lng2 - lng1);
    var a = square(Math.sin(lat_dif / 2)) + Math.cos(lat1) * Math.cos(lat2) * square(Math.sin(lng_dif / 2));
    var d = 2 * r * Math.asin(Math.sqrt(a));
    return d;
  }

  rearrange(): void {
    var temps = this.locations;
    this.locations = [];
    temps.sort((a, b) => b.distance - a.distance);;
    temps.forEach(s => {
      this.locations.push(s);
    });
    this.currentPlaceId = this.locations[0].place_id;
    this.appService.Venue = this.locations[0].formatted_address;
    this.address = this.locations[0].formatted_address;
    localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
    this.nearbySearch();
  }

  nearbySearch() {
    this.http.get<any>(webService.GoogleNearbySearch + `?lat=${this.lat}&lng=${this.lng}`).subscribe((root) => {
      if (root && root.data && root.data.length > 0) {
        root.data.forEach(place => {
          var distance = this.calculateDistanceForGoogleGeo(this.lat, this.lng, place.location.latitude, place.location.longitude);
          if (this.locations.length > 0) {
            if (distance <= 0.01) {
              this.appService.Venue = place.displayName.text;
              this.addressName = place.displayName.text;
              this.address = place.formattedAddress;
              this.currentPlaceId = place.id;
            }
          }
          place.distance = (distance * 0.621371192).toFixed(2) + " miles";
          this.nearByLocations.push(place);
        })
      }
    });
  }

  convertLatLngToAddress() {
    this.http.get<any>(webService.GoogleGeocode(this.lat, this.lng)).subscribe((root) => {
      if (root && root.results && root.results.length > 0) {
        const found = this.locations.find((obj) => {
          return obj.place_id === root.results[0].place_id;
        });
        var distance = this.calculateDistanceForGoogleGeo(this.lat, this.lng, root.results[0].geometry.location.lat, root.results[0].geometry.location.lng);
        if (!found) {
          root.results[0].distance = distance;
          this.locations.push(root.results[0]);
          this.rearrange();
        }
      } else {
        this.appService.Venue = "UNKNOW";
      }
    });
  }

  onVenueSelect(item: EventModel): void {
    this.key = item.key;
    this.locationModal.hide();
    this.router.navigate([""], { queryParams: { key: item.key } });
  }

  getSession(isFirst: boolean) {
    this.spinner.show();
    this.events = [];
    const param =
      "?latitude=" + this.lat + "&longitude=" + this.lng + "&filterType=1";
    this.http
      .get<RootEventModel>(webService.getEvent + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          if (root.data.length === 0) {
            this.askLocationModal.show();
          } else {
            if (!isFirst) {
              this.locationModal.show();
              this.askLocationModal.hide();
              root.data.forEach((element) => {
                this.events.push(element);
              });
            } else {
              this.key = root.data[0].key;
              this.router.navigate([""], { queryParams: { key: this.key } });
            }
          }
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  songRequestedKaraoke(data) {
    const speed = (data.length + 1) * 3;
    $("#marqueeP").css(
      "animation",
      `sidescroll ${speed}s linear infinite`
    );
    $("#tickerRefreshPlaylist").css(
      "height", "0px"
    );
    $("#footer").css(
      "height", "50px"
    );
    this.marquee.nativeElement.innerHTML = "";

    this.addSponsorTicker();

    let i = 0;
    data.forEach((element) => {
      if (
        element.requestType == "1" ||
        element.requestType == "2" ||
        element.requestType == "3"
      ) {
        i++;
        if (i >= 4) {
          this.addSponsorTicker();
          i = 0;
        }
        let name: string;
        if (
          element.firstName === "Anonymous" ||
          element.firstName === ""
        ) {
          name = "Someone";
        } else {
          name = element.firstName;
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const img = element.songThumb;
          const htmlImg =
            '<img alt="" class="songThumbMarquee center-crop" src="' +
            img +
            '"' +
            "</img>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            htmlImg
          );
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const html3 =
            '<a class="requestBoldSpan">' + element.songName + "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );

          if (name !== "Someone") {
            const html2 = '<a class="requestedSpan">requested by</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 = '<a class="requestBoldSpan">' + name + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          } else {
            const html2 = '<a class="requestedSpan">-</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 =
              '<a class="requestBoldSpan">' + element.songArtist + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          }
        } else if (element.requestType === "3") {
          if (element.state === "2") {
            if (name !== "Someone") {
              const html1 = '<a class="requestBoldSpan">' + name + "</a>";
              this.marquee.nativeElement.insertAdjacentHTML(
                "beforeend",
                html1
              );

              const html2 = '<a class="requestedSpan">said,</a>';
              this.marquee.nativeElement.insertAdjacentHTML(
                "beforeend",
                html2
              );
            }
            const html3 =
              '<a class="requestBoldSpan">' +
              '"' +
              element.requestValue +
              '"' +
              "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html3
            );
          }
        }
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          '<a class="bulletSpan">&#8226;</a>'
        );
      }
    });
  }

  generateUUID(): string {
    var savedUUID = localStorage.getItem(
      Constants.SETTINGS_UUID
    );
    if (savedUUID === undefined || savedUUID === null) {
      let uuid = uuidv4();
      localStorage.setItem(Constants.SETTINGS_UUID, uuid);
      savedUUID = uuid;
    }

    return savedUUID;
  }

  songRequestedFirstFriday(data: [SongRequestModel]) {
    let result = Helpers.groupBy(data, (x) => x.key);
    this.songs = [];
    let temp = [];
    for (let key in result) {
      var songFirst = result[key][0];
      var noRequested = songFirst.noRequested;
      noRequested += result[key].length - 1;
      if (isNaN(noRequested))
        noRequested = 0;
      songFirst.noRequested = noRequested;
      temp.push(songFirst);
    }
    var tempSongs = [];
    var uuid = this.generateUUID();
    temp.forEach((element) => {
      var upvoted = false;
      if (element.uuids.includes(uuid)) {
        upvoted = true;
      }
      const s = {
        key: element.key, Uri: element.songKey,
        TrackId: element.songKey, Artist: element.songArtist,
        Thumb: element.songThumb, Title: element.songName,
        Enable: true, NoRequested: element.noRequested,
        Requester: element.firstName + " " + element.lastName,
        AppleMusic: element.link_apple_music,
        UUID: element.uuid, Upvoted: upvoted, Time: element.time,
        RequesterText: "Requested by " + element.firstName + " " + element.lastName
      } as SongModel;
      tempSongs.push(s);
    });
    tempSongs.sort((a, b) => a.Time - b.Time);
    tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);
    tempSongs.forEach(s => {
      this.songs.push(s);
    });
    if (this.isPerformance) {
      this.performanceComponent.updateSongs(this.songs);
    } else {
      this.upNextComponent.updateSongs(this.songs);
    }
  }

  songRequested(data: [SongRequestModel]) {
    let result = Helpers.groupBy(data, (x) => x.songKey);
    let temp = [];
    this.songs = [];
    for (let key in result) {
      var songFirst = result[key][0];
      var noRequested = songFirst.noRequested;
      noRequested += result[key].length - 1;
      if (isNaN(noRequested))
        noRequested = 0;
      songFirst.noRequested = noRequested;
      temp.push(songFirst);
    }
    var tempSongs = [];
    var uuid = this.generateUUID();
    temp.forEach((element) => {
      var upvoted = false;
      if (element.uuids.includes(uuid)) {
        upvoted = true;
      }
      const s = {
        key: element.key, Uri: element.songKey,
        TrackId: element.songKey, Artist: element.songArtist,
        Thumb: element.songThumb, Title: element.songName,
        Enable: true, NoRequested: element.noRequested,
        Requester: element.firstName + " " + element.lastName,
        AppleMusic: element.link_apple_music,
        UUID: element.uuid, Upvoted: upvoted, Time: element.time,
        RequesterText: "Requested by " + element.Requester
      } as SongModel;
      tempSongs.push(s);
    });
    tempSongs.sort((a, b) => a.Time - b.Time);
    tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);
    tempSongs.forEach(s => {
      this.songs.push(s);
    });
    this.standardSessionComponent.updateSongs(this.songs);
  }

  GetSongRequestByKaraokeEvent() {
    if (this.key === undefined) {
      return;
    }
    this.spinner.show();
    const param = "?eventKey=" + this.key;
    this.http
      .get<RootSongRequestModel>(webService.GetSongRequestByKaraokeEvent + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.songRequestedKaraoke(root.data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  GetSongRequestByStandard() {
    this.spinner.show();
    const param = "?placeId=" + this.currentPlaceId;
    this.http
      .get<RootSongRequestModel>(webService.GetSongRequestByStandard + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.songRequested(root.data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  GetSpotifyPlaylist() {
    this.spinner.show();
    const param = "?placeId=" + this.currentPlaceId;
    this.http
      .get<any>(webService.GetSpotifyPlaylist + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.spotifyPlaylistId = root.data.id;
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, root.data.id);
        }
      });
  }

  GetSpotifyPlaylistAndGoTo() {
    this.spinner.show();
    const param = "?name=" + this.address;
    this.http
      .get<any>(webService.GetSpotifyPlaylist + param)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.spotifyPlaylistId = root.data.id;
          localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, root.data.id);
          window.open("https://open.spotify.com/playlist/" + this.spotifyPlaylistId, "_blank");
        }
      });
  }

  GetPerformance() {
    this.spinner.show();
    this.http
      .get<RootSongRequestModel>(webService.GetPerformance)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.songRequestedFirstFriday(root.data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  GetSongRequest() {
    this.spinner.show();
    this.http
      .get<RootSongRequestModel>(webService.GetSongRequest)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.songRequestedFirstFriday(root.data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  addSponsorTicker() {
    if (this.hasSponsor) {
      const shoutoutSponsorTitle = this.shoutoutSponsor.title;
      const shoutoutSponsorThumb = this.shoutoutSponsor.thumb;

      if (shoutoutSponsorTitle !== "") {
        const htmlSponsorImg =
          '<img alt="" class="songThumbMarquee center-crop" src="' +
          shoutoutSponsorThumb +
          '"' +
          "</img>";
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          htmlSponsorImg
        );

        const htmlhtmlSponsorImg1 =
          '<a class="requestBoldSpan">' + shoutoutSponsorTitle + "</a>";
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          htmlhtmlSponsorImg1
        );

        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          '<a class="bulletSpan">&#8226;</a>'
        );
      }
    }
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  btnBackClicked() {
    this.location.back();
  }

  isSafari() {
    const chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    return safariAgent;
  }

  btnCancelPaymentClicked() {
    this.paymentModal.hide();
  }

  btnMaybeLaterClicked() {
    this.tipModalComponent.tipModal.hide();
  }
}
