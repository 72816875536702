import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpParams } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { webService } from "../../webServices/api";
import { SongModel } from "../../models/SongModel";
import { AnimationOptions } from "ngx-lottie";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SpotifyService } from "../../services/SpotifyService";
import { SpotifyTrackModel } from "../../models/SpotifyTrackModel";
import { EventModel } from "../../models/EventModel";
import { RequestedModel } from "../../models/RequestedModel";
import { AMZUpdateCheckoutModel } from "../../models/AMZUpdateCheckout";
import { Constants } from "../../helpers/Constants";
import { AuthService } from "../../auth/AuthService";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { WebService } from "../../services/WebService";
import { Helpers } from "../../helpers/Helpers";
import { TipComponent } from "../base/tip.component";
import { RootSongRequestModel, SongRequestModel } from "../../models/SongRequestModel";
import { CommonModel } from "../../models/CommonModel";

declare var $: any;
declare var window: any;
declare let loginOptions: any;

@Component({
  templateUrl: "firstfriday.component.html",
})
export class FristFridayComponent
  extends TipComponent
  implements OnInit, AfterViewInit {
  @ViewChild("usernameModal") public usernameModal: ModalDirective;
  @ViewChild("sentRequestModal") public sentRequestModal: ModalDirective;
  @ViewChild("searchSongModal") public searchSongModal: ModalDirective;
  @ViewChild("askNumberModal") public askNumberModal: ModalDirective;
  @ViewChild("menuModal") public menuModal: ModalDirective;
  @ViewChild("upvoteModal") public upvoteModal: ModalDirective;

  uuid: string;
  coin = 0;

  key: string;
  searchSongs = [];
  number: string;

  radius = 2;
  radiusString = "Normal Venue";

  currentSongSelected: SongModel;
  currentSongThumb: string;
  currentSongTitle: string;
  currentSongKey: string;
  currentAppleMusic: string;
  currentSongArtist: string;
  currentRequestedBy: string;

  amazonCheckoutSessionId: string;

  shoutoutMessage = "";
  shoutoutPlaceholder =
    "Type your message. \nEx. I’d like to give a shoutout to ";
  requestType = "1";
  countSongRequest = 0;
  unlockGiftDes = "Only 5 More Requests";

  currentRequestedKey = "";

  notifyTitle = "";

  address: any;
  lat: any;
  lng: any;
  hasNoData = false;
  noLocation = false;

  payloadJSON: string;
  signature: string;

  djName: string;
  profileUrl: string;
  hasSponsor: boolean;

  defaultImage = "../../assets/images/image01.png";

  config = {
    img_item_01: "",
    img_item_02: "",
    img_item_03: "",
    item: "",
    img_reward_01: "",
    img_reward_02: "",
    img_reward_03: "",
    hasSponsor: "",
  };

  searchKeyword: string;
  shareLink: string;
  options: AnimationOptions = {
    path: "/assets/megaphone.json",
  };
  tickerOptions: AnimationOptions = {
    path: "/assets/ticker.json",
  };
  spotifyOptions: AnimationOptions = {
    path: "/assets/spotify.json",
  };

  phoneTypeFormGroup: FormGroup;
  @Input() phoneType: string;
  selectedCountryCode = "us";
  phoneCode = "+1";
  countryCodes = ["us", "ca", "vn"];

  btnTipClickedEvent: any;
  btnMenuClickedEvent: any;

  constructor(
    public analytics: AngularFireAnalytics,
    public router: Router,
    public spotify: SpotifyService,
    public route: ActivatedRoute,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public authAppService: AuthService,
    public fb: FormBuilder,
    public appService: AppService,
    public location: Location
  ) {
    super(router, route, http, webService, toastr, spinner, authAppService,
      appService,
      location
    );

    this.btnMenuClickedEvent =
      this.appService.btnMenuClicked.subscribe((event) => {
        this.menuModal.show();
      });

    this.route.queryParams.subscribe((params) => {
      this.appService.IsTipping = false;
      this.appService.IsShoutout = false;
      this.appService.Venue = "First Friday";
      this.sessionType = 4;
      this.GetSongRequest();
      this.GetSongRequestTicker();

      this.number = params["n"];
      if (this.number !== undefined) {
        localStorage.setItem(Constants.SETTINGS_NUMBER, this.number);
      } else {
        this.number = localStorage.getItem(Constants.SETTINGS_NUMBER);
      }
      this.amazonCheckoutSessionId = params["amazonCheckoutSessionId"];
      if (this.amazonCheckoutSessionId !== undefined) {
        localStorage.setItem(
          "amazonCheckoutSessionId",
          this.amazonCheckoutSessionId
        );
        this.handleAferPaid();
      }
    });
  }

  onRequestSongDonated(): void {
    this.paymentModal.hide();
    var packageId = Math.trunc((this.amount / 100));
    this.requestSong(
      this.requesterName,
      "",
      this.currentSongKey,
      this.currentSongTitle,
      this.currentSongArtist,
      this.currentSongThumb,
      packageId.toString(),
      this.shoutoutMessage,
      this.requestType);
  }

  onUpvoteDonated(): void {
    this.paymentModal.hide();
    this.spinner.show();
    var uuid = this.generateUUID();
    var upvoteCount = Math.trunc((this.amount / 100));
    const params = new HttpParams()
      .set("amount", upvoteCount.toString())
      .set("songKey", this.currentSongSelected.TrackId)
      .set("uuid", uuid)
      .set("lat", this.lat)
      .set("lng", this.lng);

    if (!this.isPerformance) {
      this.http
        .post<CommonModel>(webService.upvoteNextUp, params)
        .subscribe((root) => {
          this.spinner.hide();
          if (root.code === 0) {
            this.currentSongSelected.NoRequested += upvoteCount;
            this.currentSongSelected.Upvoted = true;
            this.upNextComponent.rearrange();
            this.upvoteModal.hide();
          } else if (root.code == 1) {
            this.toastr.error("Error", "Something went wrong");
          } else {
            this.toastr.info("Info", root.msg);
          }
        });
    } else {
      this.http
        .post<CommonModel>(webService.upvotePerformance, params)
        .subscribe((root) => {
          this.spinner.hide();
          if (root.code === 0) {
            this.currentSongSelected.NoRequested += upvoteCount;
            this.currentSongSelected.Upvoted = true;
          } else if (root.code == 1) {
            this.toastr.error("Error", "Something went wrong");
          } else {
            this.toastr.info("Info", root.msg);
          }
        });
    }
  }

  btnNotifyMeClicked() {
    const ua = navigator.userAgent.toLowerCase();
    let url;
    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      url = `sms:+18727135442&body=${this.requesterName}, press send on this text to be notified when it’s your turn to sing, ${this.currentSongTitle}.
      You’ll also be notified via text if you win Best Performance at the end of the night.`;
    } else {
      url = `sms:+18727135442&body=${this.requesterName}, press send on this text to be notified when it’s your turn to sing, ${this.currentSongTitle}. 
      You’ll also be notified via text if you win Best Performance at the end of the night.`;
    }
    window.location.href = url;
    this.sentRequestModal.hide();
  }

  onDestroy(): void {
    this.btnTipClickedEvent.unsubscribe();
  }

  private initForm() {
    this.phoneTypeFormGroup = this.fb.group({
      phoneType: [this.phoneType],
      phone: ["", [Validators.pattern("[A-Za-z0-9-_ ()]+")]],
    });
  }

  UpdateCheckoutSessionAMZ() {
    const amount = localStorage.getItem("CURRENT_AMOUNT");
    const params = new HttpParams()
      .set("amount", amount)
      .set("checkoutSessionId", this.amazonCheckoutSessionId);
    this.http
      .post<AMZUpdateCheckoutModel>(webService.UpdateCheckoutSessionAMZ, params)
      .subscribe((root) => {
        if (root.code === 0) {
          this.handleAferPaid();
          this.router.navigate([], {
            queryParams: { amazonCheckoutSessionId: null },
            queryParamsHandling: "merge",
          });
        } else {
          this.toastr.error(
            "Error",
            "Something went wrong, please check your credit/debit card information and try again."
          );
        }
      });
  }

  ngOnInit(): void {
    this.initForm();
    this.songs = [];
    this.events = [];
    this.shareLink = "https://ujay.com" + this.router.url;
    this.analytics.logEvent("Web_OpenPage");
  }

  ngAfterViewInit(): void {
    this.handlePayment();
    this.handleModal();
  }

  showUsernameModal() {
    this.requesterName = localStorage.getItem("username");
    if (
      this.requesterName === "" ||
      this.requesterName === null ||
      this.requesterName === "null" ||
      this.requesterName === undefined
    ) {
      this.requesterName = "";
      this.usernameModal.show();
    } else {
      this.donateModalComponent.updateText("1");
      this.donateModalComponent.show();
    }
  }

  searchSongKeywordChanged(q: string) {
    if (q === "") {
      this.searchSongs = [];
      return;
    }
    this.handleSearchSong(q);
  }

  handleSearchSong(q: string) {
    this.spotify.searchFirstFridaySong(q).subscribe((result) => {
      this.searchSongs = [];
      result.data.forEach((track) => {
        let artistName = track.Artist;
        var rnd = Helpers.getRandomInt(15) + 1;
        track.imageUrl = `./assets/img/bg_ff_${rnd}.png`;
        track.name = track.Title;
        track.artistName = artistName;
        track.uri = track.Id;
        this.searchSongs.push(track);
      });
    });
  }

  requestSong(
    firstName: string,
    lastName: string,
    songKey: string,
    songName: string,
    songArtist: string,
    songThumb: string,
    packageId: string,
    requestValue: string,
    requestType: string,
  ) {
    var uuid = this.generateUUID();

    const params = new HttpParams()
      .set("type", "2")
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("songKey", songKey)
      .set("songName", songName)
      .set("songArtist", songArtist)
      .set("songThumb", songThumb)
      .set("packageId", packageId)
      .set("requestValue", requestValue)
      .set("requestType", requestType)
      .set("uuid", uuid);

    this.spinner.show();

    this.http
      .post(webService.requestSong, params)
      .subscribe((root: RequestedModel) => {
        this.spinner.hide();
        if (root.code === 0) {
          this.sentRequestModal.show();
          this.currentRequestedKey = root.data;
          this.GetSongRequest();
        } else if (root.code == 1) {
          this.toastr.error("Error", "Something went wrong");
        } else {
          this.toastr.info("Info", root.msg);
        }
      });

    this.analytics.logEvent("Web_RequestSong");
  }

  handleModal() {
    this.searchSongModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1040)
          .addClass("modal-stack");
      }, 0);
    });

    this.sentRequestModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1042)
          .addClass("modal-stack");
      }, 0);
    });

    this.donateModalComponent.donateModal.onShow.subscribe(() => {
      setTimeout(function () {
        $(".modal-backdrop")
          .not(".modal-stack")
          .css("z-index", 1042)
          .addClass("modal-stack");
      }, 0);
    });

    this.searchSongModal.onHide.subscribe(() => {
      this.searchKeyword = "";
      this.searchSongs = [];
    });
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  onRequesterNameChange(value: string): void {
    this.requesterName = value;
  }

  btnUsernameDoneClicked() {
    if (this.requesterName.trim() !== "") {
      localStorage.setItem("username", this.requesterName.trim());
      this.usernameModal.hide();
      this.donateModalComponent.updateText("1");
      this.donateModalComponent.show();
    } else {
      this.requesterName = "";
      this.toastr.error(
        "Error",
        "A name is required. You can make one up. Just remember it."
      );
    }
  }

  btnSearchSongClicked() {
    this.analytics.logEvent("Web_Btn_Search");
    this.searchSongModal.show();
    setTimeout(function () {
      document.getElementById("searchInput").focus()
    }, 2)
  }

  getDisplayName() {
    this.requesterName = localStorage.getItem("username");
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = this.authAppService.displayName;
    }
    if (
      this.requesterName === null ||
      this.requesterName === undefined ||
      this.requesterName === ""
    ) {
      this.requesterName = "";
    }
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.songs = [];
  }

  onVenueSelect(item: EventModel): void {
    this.key = item.key;
    this.songs = [];
    this.router.navigate([""], { queryParams: { key: item.key } });
  }

  canRequestASong() {
    let latestRequest = localStorage.getItem(Constants.SETTINGS_LATEST_REQUEST);
    if (!latestRequest) {
      latestRequest = "0";
    }
    const now = Date.now();
    const limitTime = 3 * 60000;
    const timeFromLastRequest = now - Number(latestRequest);
    if (timeFromLastRequest > limitTime) {
      return true;
    }
    const timeLeft = limitTime - timeFromLastRequest;
    this.toastr.error("Make Another Request in " + Helpers.msToTime(timeLeft));
    return false;
  }

  onSpotifySongSelect(item: SpotifyTrackModel) {
    if (!this.canRequestASong()) {
      return;
    }
    this.userRequestType = Constants.REQUEST_TYPE_SONG_REQUEST;
    this.currentSongThumb = item.imageUrl;
    this.currentSongTitle = item.name;
    this.currentSongArtist = item.artistName;
    this.currentSongKey = item.uri;
    this.searchKeyword = "";
    this.searchSongModal.hide();
    this.showUsernameModal();
  }

  onStandardSongSelect(item: SongModel): void {
    if (item.key == "")
      return;

    this.currentSongSelected = item;
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.key;
    this.currentAppleMusic = item.AppleMusic;
    this.currentRequestedBy = "Requested by " + item.Requester;

    this.upvoteModal.show();
  }

  onPerformanceSongSelect(item: SongModel): void {
    if (item.key == "")
      return;

    this.currentSongSelected = item;
    this.currentSongThumb = item.Thumb;
    this.currentSongTitle = item.Title;
    this.currentSongArtist = item.Artist;
    this.currentSongKey = item.key;
    this.currentAppleMusic = item.AppleMusic;
    this.currentRequestedBy = "Requested by " + item.Requester;

    this.upvoteModal.show();
  }

  btnCancelPaymentClicked() {
    this.paymentModal.hide();
  }

  btnMaybeLaterClicked() {
    this.donateModalComponent.donateModal.hide();
  }

  btnCloseUpvoteClicked() {
    this.upvoteModal.hide();
  }

  btnContactUsClicked() {
    window.open("https://ujayapp.com/#contactus", "_blank");
  }

  btnGetDJClicked() {
    window.open("https://apps.apple.com/us/app/ujay/id1447792017", "_blank");
  }

  btnDJResourcesClicked() {
    window.open("https://ujayapp.com", "_blank");
  }

  btnUpvoteClicked() {
    this.userRequestType = Constants.REQUEST_TYPE_UPVOTE;
    this.upvoteModal.hide();
    this.donateModalComponent.updateText("2");
    this.donateModalComponent.show();
  }

  btnSpotifyClicked() {
    if (this.currentSongSelected == null)
      return;
    window.open(this.currentSongSelected.Uri, "_blank");
  }

  btnAppleMusicClicked() {
    if (this.currentSongSelected == null)
      return;
    window.open(this.currentSongSelected.AppleMusic, "_blank");
  }

  btnTickerClicked() {
    this.isPerformance = true;
    this.GetPerformance();
  }

  StandardSessionBtnClicked(event: string) {
    switch (event) {
      case "btnSearchSongClicked":
        this.btnSearchSongClicked();
        break;
      case "btnBackClicked":
        this.isPerformance = false;
        this.GetSongRequest();
        break;
    }
  }

  GetSongRequestTicker() {
    this.spinner.show();
    this.http
      .get<RootSongRequestModel>(webService.GetPerformance)
      .subscribe((root) => {
        this.spinner.hide();
        if (root.code === 0) {
          var data = [];
          const s = { requestType: "3", requestValue: "CLICK HERE to vote for the best performer" } as SongRequestModel;
          data.push(s);
          var i = 0;
          root.data.forEach(element => {
            i++;
            data.push(element);
            if (i == 2) {
              data.push(s);
              i = 0;
            }
          });
          this.songRequestedTicker(data);
        } else {
          this.toastr.error("Error", root.msg);
        }
      });
  }

  songRequestedTicker(data) {
    const speed = (data.length + 1) * 3;
    $("#marqueeP").css(
      "animation",
      `sidescroll ${speed}s linear infinite`
    );

    this.marquee.nativeElement.innerHTML = "";

    data.forEach((element) => {
      if (
        element.requestType == "1" ||
        element.requestType == "2" ||
        element.requestType == "3"
      ) {
        let name: string;
        if (
          element.firstName === "Anonymous" ||
          element.firstName === ""
        ) {
          name = "Someone";
        } else {
          name = element.firstName;
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const img = element.songThumb;
          const htmlImg =
            '<img alt="" class="songThumbMarquee center-crop" src="' +
            img +
            '"' +
            "</img>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            htmlImg
          );
        }

        if (element.requestType === "1" || element.requestType === "2") {
          const html3 =
            '<a class="requestBoldSpan">' + element.songName + "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );

          if (name !== "Someone") {
            const html2 = '<a class="requestedSpan">performed by</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 = '<a class="requestBoldSpan">' + name + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          } else {
            const html2 = '<a class="requestedSpan">-</a>';
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html2
            );

            const html1 =
              '<a class="requestBoldSpan">' + element.songArtist + "</a>";
            this.marquee.nativeElement.insertAdjacentHTML(
              "beforeend",
              html1
            );
          }
        } else if (element.requestType === "3") {
          const html3 =
            '<a class="requestBoldSpan">' +
            element.requestValue +
            "</a>";
          this.marquee.nativeElement.insertAdjacentHTML(
            "beforeend",
            html3
          );
        }
        this.marquee.nativeElement.insertAdjacentHTML(
          "beforeend",
          '<a class="bulletSpan">&#8226;</a>'
        );
      }
    });
  }

  googlePlaceSelected(): void {
        
  }
}
